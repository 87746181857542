import React from 'react';
import { useStyles } from './styles';
import wordCountProvider from '../word-count/word-count';
import ShortBlogAndPressContentHeader from '../ShortBlogAndPressContentHeader';
import AuthorDetails from '../AuthorDetails';
import CategorySocialMedia from '../CategorySocialMedia';
import clsx from 'clsx';
import './ShortBlogDetail.scss';

const ShortBlogDetail = ({ data, path, bucketname, basePath,images,blogLandingUrl,location}) => { 
  const classes = useStyles(images);
  const pathName = `${path}`;

  let TotalContent = '';
  function generateTotalContent(element) {
    if(element){
      element.forEach((el) => {
        if(el?.content){
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        }
      });
    }
  }

  if (data?.richTextContent1) {
    generateTotalContent(JSON.parse(data?.richTextContent1?.raw).content);
  }
  if (data?.richTextContent2) {
    generateTotalContent(JSON.parse(data?.richTextContent2?.raw).content);
  }
  if (data?.rightSectionParagraphText) {
    generateTotalContent(JSON.parse(data?.rightSectionParagraphText?.raw).content);
  }
  if (data?.description) {
    generateTotalContent(JSON.parse(data?.description?.raw).content);
  }

  return (
    <>
      <div className="short-blog-main">
        <div className={clsx('bg-donuts-right', classes['bg-donuts-right-img'])}>
          <ShortBlogAndPressContentHeader data={data} images={images} title={data?.shortBlogTitle}/>
          <CategorySocialMedia
            data={data}
            bucketname={bucketname}
            type={process.env.GATSBY_CARTUS_BLOG}
            path={pathName}
            title={data?.shortBlogTitle}
            wordCount={wordCountProvider(TotalContent)}
            landingUrl={blogLandingUrl}
            basePath={basePath}
            images={images}
            location={location}
          />
          <AuthorDetails data={data} />
        </div>
      </div>
    </>
  );
};

export default ShortBlogDetail;
