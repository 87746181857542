import React from 'react';
import LongBlogAndPressContentHeader from '../LongBlogAndPressContentHeader';
import LongBlogAndPressContentOurCompany from '../LongBlogAndPressContentOurCompany';
import CategorySocialMedia from '../CategorySocialMedia';
import AuthorDetails from '../AuthorDetails';
import { myStyles } from './style';
import wordCountProvider from '../word-count/word-count';
import clsx from 'clsx';
import './LongBlog.scss';

const LongBlog = ({ data, bucketname, blogslug, basePath, images,blogLandingUrl,location }) => {
  const path = `${blogslug}`;
  const classes = myStyles(images);
  let TotalContent = '';
  let TotalTextContent = '';

  function generateTotalContent(element) {
    if (element) {
      element.forEach((el) => {
        if (el?.content) {
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        }
      });
    }
  }

  if (data?.richTextContent1?.raw) {
    generateTotalContent(JSON.parse(data?.richTextContent1?.raw).content);
  }
  if (data?.subText?.raw) {
    generateTotalContent(JSON.parse(data?.subText?.raw).content);
  }
  if (data?.richTextContent2?.raw) {
    generateTotalContent(JSON.parse(data?.richTextContent2?.raw).content);
  }
  if (data?.richTextContent3?.raw) {
    generateTotalContent(JSON.parse(data?.richTextContent3?.raw).content);
  }
  if (data?.richTextHeaderWithBulletPoints?.raw) {
    const bulletContent = JSON.parse(data?.richTextHeaderWithBulletPoints?.raw).content.filter(
      (element) => element.nodeType === 'unordered-list' || element.nodeType === 'ordered-list'
    );
    const nonBulletContent = JSON.parse(data?.richTextHeaderWithBulletPoints?.raw).content.filter(
      (element) => element.nodeType !== 'unordered-list' && element.nodeType !== 'ordered-list'
    );
    bulletContent.forEach((ele) => {
      ele.content.forEach((item) => {
        generateTotalContent(item.content);
      });
    });

    generateTotalContent(nonBulletContent);
  }

  if (data?.leftSectionContent?.raw) {
    generateTotalContent(JSON.parse(data?.leftSectionContent?.raw).content);
  }
  if (data?.rightSectionContent?.raw) {
    generateTotalContent(JSON.parse(data?.rightSectionContent?.raw).content);
  }
  const wordCount = wordCountProvider(TotalContent) + wordCountProvider(TotalTextContent);
  return (
    <>
      <div className="longblog_main" id={data?.entryTitle}>
        <div className={clsx('donuts-right', classes['bg-donuts-right'])}>
          <LongBlogAndPressContentHeader data={data} title={data?.longBlogTitle} images={images} />
        </div>
        {(data?.rightSectionContent?.raw ||
          data?.leftSectionContent?.raw) && (
          <LongBlogAndPressContentOurCompany
            leftSection={data?.leftSectionContent?.raw}
            rightSection={data?.rightSectionContent?.raw}
          />
        )}
        <CategorySocialMedia
          data={data}
          bucketname={bucketname}
          type={process.env.GATSBY_CARTUS_BLOG}
          path={path}
          wordCount={wordCount}
          title={data?.longBlogTitle}
          landingUrl={blogLandingUrl}
          basePath={basePath}
          images={images}
          location={location}
        />
        <AuthorDetails data={data} />
      </div>
    </>
  );
};
export default LongBlog;
