import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import BlogContent from '../components/BlogContent';
export default function BlogDetailsPage({ location,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulBlogs?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url+'?fm=webp&q=100'}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.contentfulPartnerSite?.organizationYoutubeLink}
          forPage = 'blogs'
          location={location}
        />
        <BlogContent
          data={data?.contentfulBlogs}
          bucketname={pageContext?.bucketName}
          basePath={pageContext.basePath}
          images={data?.contentfulComponentBackgroundImages}
          blogLandingUrl={data?.contentfulCategoryTagsLandingPage?.slug}
          location={location}
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query pageTemplate($contentful_id: String, $locale: String, $partnerId: String) {
    contentfulCategoryTagsLandingPage{
      entryTitle
      slug
      }
    contentfulPartnerSite(partnerId: {eq: $partnerId}) {
      navigation {
        menus {
          menuLink {
            link {
              ... on ContentfulInternalLink {
                id
                referenceToPage {
                  ... on ContentfulCustomResourceHub {
                    id
                    moreBlogs {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    contentfulComponentBackgroundImages {
      blueDonutBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      downloadArrowImage {
        description
        file {
          url
        }
      }
      greenDonutBackgroundImage {
        file {
          url
        }
      }
      gridBridgeImage {
        file {
          url
        }
      }
      linkedlnIcon {
        description
        file {
          url
        }
      }
      twitterIcon {
        description
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
      facebookIcon {
        description
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }

    contentfulBlogs(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      blogSubscribe {
        heading
        iFrameLink
        rightArrowImage {
          fixed {
            base64
            tracedSVG
            aspectRatio
            srcWebp
            srcSetWebp
          }
          file {
            url
          }
          description
        }
      }
      id
      entryTitle
      contentful_id
      featured
      blogSlug
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      blogContent {
        ...shortblog
        ...longblog
        ...podcastblog
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
  }

  fragment shortblog on ContentfulShortBlog {
    downloadPdfOrLink
    downloadPdf {
      file {
        url
      }
    }
    downloadLink{
      link {
        ... on ContentfulInternalLink {
            pageName
        }
        ... on ContentfulWebLink {
          url
          openInANewTab
          itemId
        }
      }
    }    
    thumbnailImage {
      description
      file {
        url
      }
    }
    categoryType
    dateOfPublication(locale: "us")
    shortBlogTitle
    authorDetails{
      authorName
      authorImage {
        description
        file {
          url
        }
      }
      authorDesignation
      postedBy
      authorDescription {
        raw
      }
    }
    heroImage {
      description
      file {
        url
      }
    }
    topic
    subTopic
    description {
      raw
    }
    displayPrimaryCta
    labelForPrimaryCta
    richTextContent1 {
      raw
    }
    richTextContent2 {
      raw
    }
    rightSectionParagraphText {
      raw
    }
    categoryTitle
    tagsTitle

    leftSectionTopic {
      raw
    }
    leftSectionSubTopic {
      raw
    }
    leftSectionImageOrVideo
    leftSectionVideo{
      ...compVideo
    }
    leftSectionImage {
      description
      file {
        url
      }
    }
    rightSectionImageOrVideo
    rightSectionVideo{
      ...compVideo
    }
    rightSectionImage {
      description
      file {
        url
      }
    }
    categoryList {
      categoryName
      contentful_id
      entryTitle
    }
    tagList {
      contentful_id
      entryTitle
      tagName
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment longblog on ContentfulLongBlog {
    id
    contentful_id
    entryTitle
    categoryType
    dateOfPublication
    longBlogTitle
    heroImage {
      description
      title
      file {
        url
      }
    }
    thumbnailImage {
      description
      file {
        url
      }
    }
    authorDetails{
      authorName
      authorImage {
        description
        file {
          url
        }
      }
      authorDesignation
      postedBy
      authorDescription {
        raw
      }
    }
    richTextContent1 {
      raw
    }
    title
    subTitle
    subText {
      raw
    }
    downloadPdfOrLink
    downloadPdf {
      file {
        url
      }
    }
    downloadLink{
      link {
        ... on ContentfulInternalLink {
            pageName
        }
        ... on ContentfulWebLink {
          url
          openInANewTab
          itemId
        }
      }
    }
    displayPrimaryCta
    labelForPrimaryCta
    richTextContent2 {
      raw
    }
    primaryVideoOrImage
    primaryVideo {
      ...compVideo
    }
    primaryImage {
      description
      title
      file {
        url
      }
    }
    richTextContent3 {
      raw
    }
    secondaryVideoOrImage
    secondaryVideo {
      ...compVideo
    }
    secondaryImage {
      description
      title
      file {
        url
      }
    }
    richTextHeaderWithBulletPoints {
      raw
    }
    leftSectionContent {
      raw
    }
    rightSectionContent {
      raw
    }
    categoryTitle
    tagsTitle

    categoryList {
      categoryName
      contentful_id
      entryTitle
    }
    tagList {
      contentful_id
      entryTitle
      tagName
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compVideo on ContentfulCustomVideo {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    entryTitle
    videoComponent {
      entryTitle
      videoType
      videoId
      videoTitle
      dateOfPublishing
    }
  }

  fragment podcastblog on ContentfulPodcastBlog {
    id
    contentful_id
    entryTitle
    podcastThumbnailImage {
      title
      description
      file {
        url
      }
    }
    podcastBlogTileImage {
      description
      file {
        url
      }
    }
    categoryType
    dateOfPublication
    podcastBlogTitle
    audioPlayer {
      raw
    }
    similarTopicTitle
    similarTopicsSection {
      raw
    }
    richTextContent1 {
      raw
    }
    categoryTitle
    tagsTitle
    authorDetails{
      authorName
      authorImage {
        description
        file {
          url
        }
      }
      authorDesignation
      postedBy
      authorDescription {
        raw
      }
    }
    categoryList {
      categoryName
      contentful_id
      entryTitle
    }
    tagList {
      contentful_id
      entryTitle
      tagName
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
