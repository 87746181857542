/* eslint-disable import/no-cycle */
import React from 'react';
import LongBlog from '../LongBlog';
import PodcastBlog from '../PodcastBlog';
import ShortBlogDetail from '../ShortBlogDetail'
import RequestFormSubsPopup from './RequestFormSubsPopup';

const BlogContent=({data,bucketname,basePath,images,blogLandingUrl,location})=> {
  let blogType = data?.blogContent?.sys?.contentType?.sys?.id
  const renderBlogContent = (blogType) => {
    switch (blogType) {
      case "logBlog":
        return (
          <LongBlog 
          data={data.blogContent}
          bucketname={bucketname}
          blogtype={blogType}
          blogslug={data.blogSlug}
          basePath={basePath}
          images={images}
          blogLandingUrl={blogLandingUrl}
          location={location}
          />
        )
      case "shortBlog":
        return (
          <ShortBlogDetail
          data={data.blogContent}
          path={data.blogSlug}
          bucketname={bucketname}
          blogtype={blogType}
          basePath={basePath}
          images={images}
          blogLandingUrl={blogLandingUrl}
          location={location}
          />
        )
      case "customPodcastBlog":
        return (
          <PodcastBlog
          data={data.blogContent}
          bucketname={bucketname}
          blogtype={blogType}
          blogslug={data.blogSlug}
          basePath={basePath}
          images={images}
          blogLandingUrl={blogLandingUrl}
          location={location}
          />
      )
      default:
        return (
          <h1>Select a proper type</h1>
        )
    }
  }
  return (
    <>
      {renderBlogContent(blogType)}
      <RequestFormSubsPopup 
          heading={data?.blogSubscribe?.heading}
          iFrameLink={data?.blogSubscribe?.iFrameLink}
          rightArrowImage={data?.blogSubscribe?.rightArrowImage}
          blogSubscribe={data?.blogSubscribe}
      />
    </>
  );
}

export default BlogContent