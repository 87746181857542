import React from 'react';
import { Grid } from '@mui/material';
import './PodcastBlogText.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { myStyles } from './style';
import Container from '@material-ui/core/Container';

const PodcastBlogText = ({data, images}) => {
  const classes = myStyles(images);
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (children != '' ? <p>{children}</p> : ''),
    },
  };

  return (
    <Container maxWidth="xl" className='container-padding-none'>
    <Grid container className="podcast-blog-text-cls">
      <Grid container className="section-with-blue-div">
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        {(data?.similarTopicTitle || data?.similarTopicsSection?.raw) && (
          <div className="text-with-blue-div">
          <Grid item xl={7} lg={9} md={7} sm={8} xs={12}>
          <Grid className={clsx('text-with-blue-section', classes['bg-cta-dot-matrix-img'])} item>
            <img
              src={images?.downloadArrowImage?.file?.url+'?fm=webp&q=100'}
              alt={images?.downloadArrowImage?.description}
              className="green-arrow"
              locId="arrowImage"
            />
            <div  className="text-div">
              {data?.similarTopicTitle && (
              <div className="title" locId='similarTopicTitle'>{data?.similarTopicTitle}</div>              
              )}
              {data?.similarTopicsSection?.raw && (
                documentToReactComponents(JSON.parse(data?.similarTopicsSection?.raw),options)
                )}
            </div>
           
          </Grid>
         
          </Grid>
          </div>
          )}
        </Grid>
       
      
       
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} locId='richText1AndrichText2'>
          <div className="rich-text-para">   
            {data?.richTextContent1?.raw && (
                documentToReactComponents(JSON.parse(data?.richTextContent1?.raw),options)
                )}
          </div>      
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
};

export default PodcastBlogText;
