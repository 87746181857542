import React from 'react';
import PodcastHeader from './PodcastBlogHeader';
import PodcastBlogText from './PodcastBlogText';
import CategorySocialMedia from '../CategorySocialMedia';
import AuthorDetails from '../AuthorDetails';
import { myStyles } from './style';
import wordCountProvider from '../word-count/word-count';
import clsx from 'clsx';
import './PodcastBlog.scss';

const PodcastBlog = ({ data, bucketname, blogslug, basePath,images,blogLandingUrl,location }) => {
  const path = `${blogslug}`;
  const classes = myStyles(images);
  let TotalContent = '';
  function generateTotalContent(element) {
    element.forEach((el) => {
      el?.content.forEach((childElement) => {
        TotalContent = TotalContent + ' ' + childElement?.value?.trim();
      });
    });
  }

  if (data?.richTextContent1?.raw) {
    generateTotalContent(JSON.parse(data?.richTextContent1?.raw).content);
  }
  if (data?.similarTopicsSection?.raw) {
    generateTotalContent(JSON.parse(data?.similarTopicsSection?.raw).content);
  }
  if (data?.richTextContent2?.raw) {
    generateTotalContent(JSON.parse(data?.richTextContent2?.raw).content);
  }
  const wordCount = wordCountProvider(TotalContent);

  return (
    <div className="PodcastBlog_main" id={data?.entryTitle}>
      <div className={clsx('donuts-right', classes['bg-donuts-right'])}>
        <PodcastHeader data={data} images={images}/>
        <PodcastBlogText data={data} images={images}/>

        <CategorySocialMedia
          data={data}
          bucketname={bucketname}
          type={process.env.GATSBY_CARTUS_BLOG}
          path={path}
          wordCount={wordCount}
          title={data?.podcastBlogTitle}
          landingUrl={blogLandingUrl}
          basePath={basePath}
          images={images}
          location={location}
        />
          <AuthorDetails data={data} />
      </div>
    </div>
  );
};

export default PodcastBlog;
