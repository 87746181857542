import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const { documentToPlainTextString } = require('@contentful/rich-text-plain-text-renderer');
const AudioPlayer = ({ audioplayer }) => {
  const [divID, setDivID] = useState('');
  const [scriptSrc, setScriptSrc] = useState('');
  useEffect(() => {
    if (audioplayer?.raw) {
      const audioString = documentToPlainTextString(JSON.parse(audioplayer?.raw));
      const audioHtml = document.createRange().createContextualFragment(audioString);
      setDivID(audioHtml.firstChild.id);
      setScriptSrc(audioHtml.lastChild.src);
    }
  }, []);
  return (
    <>
      <div id={divID} locid="Audioplayer"></div>
      <Helmet>
        <script type="text/javascript" src={scriptSrc} charset="utf-8" async={true}/>
      </Helmet>
    </>
  );
};
export default AudioPlayer;
