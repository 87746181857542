import React from 'react';
import { Grid, Card, CardContent, CardMedia } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import './AuthorDetails.scss';
import Container from '@material-ui/core/Container';

export default function AuthorDetails({ data }) {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (children != '' ? <p>{children}</p> : ''),
    },
  };
  return (
    <>
    <Container maxWidth="xl">
      <Grid container>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="author-main-cls">
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                spacing={2}
                className="cols-alignment"
              >
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="author-img-txt">
                  <CardMedia
                    component="img"
                    image={data?.authorDetails?.authorImage?.file.url+'?fm=webp&q=100'}
                    className="img-alignment"
                    alt={data?.authorDetails?.authorImage?.description}
                    locId="authorImage"
                  />
                  <p className="name" locId="authorName">
                    {' '}
                    {data?.authorDetails?.authorName}{' '}
                  </p>
                  <p className="designation" locId="authorDesignation">
                    {' '}
                    {data?.authorDetails?.authorDesignation}{' '}
                  </p>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className="testimonialInfo">
                  <CardContent className="author-card-space">
                    <p className="powerby" locId="postedBy">
                      {data?.authorDetails?.postedBy}{' '}
                    </p>
                    <p className="description" locId="authorDescription">
                      {data?.authorDetails?.authorDescription?.raw &&
                        documentToReactComponents(JSON.parse(data?.authorDetails?.authorDescription.raw),options)}
                    </p>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
           </Grid>
      </Grid>
      </Container>
    </>
  );
}
