import React from 'react';
import Grid from '@material-ui/core/Grid';
import './PodcastBlogHeader.scss';
import { myStyles } from './style';
import clsx from 'clsx';
import AudioPlayer from '../../AudioPlayer';
import { DateFormatUtils } from '../../../utils/date-formatter';
import Container from '@material-ui/core/Container';

const PodcastBlogHeader = ({ data, images }) => {
  const classes = myStyles(images);
  const { date, month, year } = DateFormatUtils.DateMonthYearFormatter(data?.dateOfPublication);
  return (
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-blog-header-cls">
        {/* **********************    Text-with-Image-row    ********************** */}
        {(data?.podcastThumbnailImage || (data?.categoryType || data.dateOfPublication) || data?.podcastBlogTitle || data?.audioPlayer) && (
          <Grid container className="Text-with-hero-img">
            <Grid container item xl={5} lg={5} md={12} sm={12} xs={12}>
              {/* <Grid item xl={0} lg={0} md={1} sm={0} xs={0}></Grid> */}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="header-text">
                  {(data?.podcastThumbnailImage || (data?.categoryType || data.dateOfPublication) || data?.podcastBlogTitle) && (
                    <div className='header-text-div'>
                      {data?.podcastThumbnailImage && (
                        <div className={clsx('header-image', classes['bg-header-dot-matrix'])}>
                          <img
                            src={data?.podcastThumbnailImage?.file?.url + '?fm=webp&q=100'}
                            alt={data?.podcastThumbnailImage?.description}
                          />
                        </div>
                      )}
                      {(data?.categoryType || data.dateOfPublication) && (
                        <p className="header-date" locId="categoryType">
                          {data?.categoryType} / {date} {month} {year}
                        </p>
                      )}
                      {data?.podcastBlogTitle && (
                        <h1 className="header-title" locId="podcastBlogTitle">
                          {data?.podcastBlogTitle}
                        </h1>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            {data?.audioPlayer && (
              <Grid item xl={7} lg={7} md={12} sm={12} xs={12} locId="audioPlayer">
                <div className="header-heroimg" tabindex="0">
                  <AudioPlayer audioplayer={data?.audioPlayer} />
                </div>
              </Grid>
            )}
          </Grid>

        )}

      </Grid>
    </Container>
  );
};

export default PodcastBlogHeader;
