import React, { useState } from 'react';
import { Grid, Button, Box } from '@material-ui/core';
import './Subscribe.scss';

export default function RequestFormSubsPopup({
  rightArrowImage,
  iFrameLink,
  heading,
  blogSubscribe,
}) {
  const [open, setOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }
  return (
    <Grid elevation={0} className="request-form-btn-subs">
      {showTopBtn && blogSubscribe && (
        <Button tabindex = '0'
          className={open ? 'request-form-btn open' : 'request-form-btn'}
          style={{ background: '#173159' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }} >
            <Button tabindex="0" className="request-form-btn-open" onClick={() => setOpen(!open)}>
              {open ? (
                <img
                  src={rightArrowImage?.file?.url+'?fm=webp&q=100'}
                  alt={rightArrowImage?.description}
                  className="arrow-icon right"
                  locId="rightArrowImage"
                />
              ) : (
                <div className="request-form-btn-wrap" >
                  <img
                    src={rightArrowImage?.file?.url+'?fm=webp&q=100'}
                    alt={rightArrowImage?.description}
                    className="arrow-icon left"
                    locId="rightArrowImage"
                  />
                  <span locId="heading">{heading}</span>
                </div>
              )}
            </Button>

            {open && (
              <>
                <div className="request-form-btn-container">
                  {heading}
                  <div className="subscribeForm">
                    {iFrameLink && (
                      <iframe
                        src={iFrameLink}
                        width="100%"
                        height="100%"
                        type="text/html"
                        frameborder="0"
                        allowTransparency="true"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </Box>
        </Button>
      )}
    </Grid>
  );
}
